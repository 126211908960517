import React, { FC, useEffect, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server'
import { useTranslation } from 'react-i18next';
import DataTable from '../../components/DataTable';
import { CreateFormDto, emptyForm, Form, FormColumns } from '../../models/forms/forms.module';
import {
	createForm,
	deleteForm, duplicateForm,
	findAllForms,
	updateForm,
} from '../../models/forms/forms.repository';
import { IPages } from '../../frontend/src/Interfaces/IPages';
import {IFields, ITableFunctions} from '../../frontend/src/Interfaces/IGeneral';
import DropWindow from '../../components/DropWindow';
import VForm from '../../frontend/src/Components/Views/VForm/VForm';
import DragWindow from '../../components/DragWindow';
import '../../styles/_nestedNav.scss';
import Button from '../../components/bootstrap/Button';
import {
	ArrowBackIos,
	ArrowForwardIos,
	CancelRounded, CopyAll,
	KeyboardArrowDown,
	KeyboardArrowUp,
	Save,
} from '@mui/icons-material';
import { emptyPage } from '../../models/pages/pages.module';
import classNames from 'classnames';
import {
	changeOrder,
	createFormField,
	createPage,
	removeField,
	removePage,
	updateField,
	updatePage,
} from '../../models/pages/pages.repository';
import { FormForm } from '../../components/FormForm';
import { FieldForm } from '../../components/FieldForm';
import { PageForm } from '../../components/PageForm';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../components/bootstrap/Modal';
import StyleForm from '../../components/StyleForm';
import ImageForm from '../../components/ImageForm';
import TemplateForm from '../../components/TemplateForm';
import toast from 'react-hot-toast';
import { Backdrop, Breadcrumbs, Chip, Container } from '@mui/material';
import { emphasize, styled } from '@mui/material/styles';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {getDefaultStyleAttributes, resetToInitialStyles} from '../../helpers/Form_helper';
import useDarkMode from '../../hooks/useDarkMode';
import {AxiosError} from "axios";
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import EErrorBoundary from "../../frontend/src/Components/EErrorBoundary/EErrorBoundary";
import CustomFlow from "../../components/CustomFlow/CustomFlow"
import {API_BASE_PATH, FRONTEND_BASE_PATH} from "../../API/API-routes";

interface BaseDataTableProps {
	isFluid?: boolean;
	name: string;
}

const FormsTable: FC<BaseDataTableProps> = ({ isFluid, name }) => {
	const { t } = useTranslation(['translation', 'menu']);
	const { themeStatus } = useDarkMode();

	const [allForms, setAllForms] = useState<Array<Form> | null>(null);
	const params = new URLSearchParams(window.location.search);

	const [editWindowsOpen, setEditWindowsOpen] = useState<{ open: boolean; type: string }>({
		open: false,
		type: '',
	});
	const [currentPage, setCurrentPage] = useState<number>(0);
	const [pages, setPages] = useState<Array<IPages> | null>(null);
	const [newField, setNewField] = useState<IFields | null>(null);
	const [currentPageForm, setCurrentPageForm] = useState<IPages | null>(null);
	const [currentForm, setCurrentForm] = useState<Form | null>(null);
	const [fieldFormOpen, setFieldFormOpen] = useState<boolean>(false);
	const [flowOpen, setFlowOpen] = useState<boolean>(false)
	const [formEditMode, setFormEditMode] = useState<
		'' | 'image' | 'style' | 'page-field' | 'page-style' | 'page-image' | 'page' | 'template'
	>('');

	const open = params.get('open') === 'true';

	useEffect(() => {
		if(allForms === null) {
			getAllForms();
		}
	}, []);

	useEffect(() => {
		if (!editWindowsOpen.open && (currentForm || pages || currentPageForm || formEditMode)) {
			setFormEditMode('');
			setCurrentPageForm(null);
			setCurrentForm(null);
			setPages(null);
			setNewField(null);
			setFieldFormOpen(false);
			setCurrentPage(0);
			if (open) {
				params.delete('type');
				params.delete('open');
			}
		}
		if(!editWindowsOpen.open) {
			resetToInitialStyles()
		}
	}, [editWindowsOpen]);

	useEffect(() => {
		if (currentForm && currentForm.pages) {
			setPages(currentForm.pages);
			if (currentPageForm) {
				setCurrentPageForm(currentForm.pages[currentPage]);
			}
		} else {
			setPages(null);
		}
	}, [currentForm]);

	useEffect(() => {
		const type = params.get('type');

		if (open && type === 'add') {
			setEditWindowsOpen({ open, type: type });
			setPages([]);
			setFormEditMode('');
			setCurrentForm(emptyForm);
		}
	}, [open]);

	const getAllForms = () => {
		findAllForms()
			.then((res) => {
				if (res) setAllForms(res);
			})
			.catch((error) => console.log(error));
	};

	const handleDrop = (item: IFields, pageIndex: number) => {
		if (pages) {
			let newDroppedItems = pages.slice();
			let newFields: Array<IFields> = [];
			let order = 1;

			if (
				newDroppedItems[pageIndex].page_fields &&
				currentForm &&
				currentForm.pages &&
				currentForm.pages?.length > 0
			) {
				newFields = newDroppedItems[pageIndex].page_fields!.slice();
				order = currentForm.pages[pageIndex]?.page_fields?.length ?? 1;
			}

			newFields.pop();
			newFields.push({ ...item, order: order });

			newDroppedItems[pageIndex] = {
				...newDroppedItems[pageIndex],
				page_fields: [...newFields],
			};

			setCurrentForm((prevState: any) => ({ ...prevState, pages: newDroppedItems }));
			setNewField({ ...item, order: order });
			setFormEditMode('page-field');
			setFieldFormOpen(true);
		}
	};

	const addNewItem = (item: IFields, pageIndex: number) => {
		if (pages) {
			let newDroppedItems = pages.slice();
			let newFields: Array<IFields> = [];
			if (newDroppedItems[pageIndex].page_fields) {
				newFields = newDroppedItems[pageIndex].page_fields!.slice();
			}

			item.form_uuid = currentForm!.uuid;

			newFields.push(item);
			newDroppedItems[pageIndex] = {
				...newDroppedItems[pageIndex],
				page_fields: [...newFields],
			};

			setCurrentForm((prevState: any) => ({ ...prevState, pages: [...newDroppedItems] }));
			setCurrentPageForm(newDroppedItems[pageIndex]);
			setCurrentPage(pageIndex);
			setFormEditMode('page-field');
		}
	};

	const toggleActive = (form: Partial<Form>) => {
		if (form.uuid) {
			toast.promise(
				updateForm(form.uuid, { active: !form.active }).then((res) => {
					if (res) {
						getAllForms();
					}
				}),
				{
					loading: t('updatingForm'),
					success: t('successUpdatingForm'),
					error: t('errorUpdatingForm'),
				},
			);
		}
	};

	const saveForm = (formData?: Form) => {
		let currentFormData = currentForm;

		if (formData) {
			currentFormData = formData;
		}

		if (currentFormData && currentFormData?.name) {
			/**
			 * Update Form when current form is already existing
			 */
			let styles = currentFormData.styles
				? [...currentFormData.styles]
				: getDefaultStyleAttributes();

			let body: CreateFormDto = {
				name: currentFormData.name,
				description: currentFormData.description,
				cancelLink: currentFormData.cancelLink,
				successLink: currentFormData.successLink,
				styles: styles,
				email_template: currentFormData.email_template,
				selected_template_id: currentFormData.selected_template_id,
			};

			if (currentFormData.uuid) {
				toast.promise(
					updateForm(currentFormData.uuid, body).then((res) => {
						if (res) {
							setCurrentForm(res);
							getAllForms();
							if (formEditMode === 'style') {
								setFormEditMode('');
							}
						}
					}),
					{
						loading: t('updatingForm'),
						success: t('successUpdatingForm'),
						error: t('errorUpdatingForm'),
					},
				);
			} else {
				toast.promise(
					createForm(body)
						.then((res) => {
							if (res) {
								setCurrentForm(res);
								getAllForms();
								setEditWindowsOpen({ open: true, type: 'edit' });
								setFormEditMode('page');
							}
						})
						.catch((error) => error),
					{
						loading: t('savingForm'),
						success: t('successSavingForm'),
						error: t('errorSavingForm'),
					},
				);
			}
		} else {
			toast.error(t('Please Add a name!'));
		}
	};

	const saveFormStyle = (styleObj: { [key: string]: string }) => {
		if (currentForm && currentForm.uuid) {
			let styleArr = Object.keys(styleObj).filter(key => key !== 'formLogo').map((styleAttribute) => {
				return {
					name: styleAttribute,
					value: styleObj[styleAttribute],
				};
			});

			toast.promise(
				updateForm(currentForm.uuid, { styles: styleArr }).then((res) => {
					if (res) {
						setCurrentForm(res);
						getAllForms();
						if (formEditMode === 'style') {
							setFormEditMode('');
						}
					}
				}),
				{
					loading: t('updatingForm'),
					success: t('successUpdatingForm'),
					error: t('errorUpdatingForm'),
				},
			);
		}
	};

	const removeItem = (fieldIndex: number, pageIndex: number) => {
		if (currentForm && currentForm?.pages) {
			let newPages = [...currentForm.pages];
			let newFields: Array<IFields> = [];
			if (newPages[pageIndex].page_fields) {
				newFields = [...newPages[pageIndex].page_fields!];
			}
			const fieldToDelete = newFields[fieldIndex];
			if (fieldToDelete?.id !== undefined) {
				removeField(fieldToDelete.id).then((res: Array<IFields>) => {
					newPages[currentPage].page_fields = [...res];
					setCurrentForm((prevState: any) => ({ ...prevState, pages: newPages }));
				});
			} else {
				newFields.splice(fieldIndex, 1);
				newPages[pageIndex] = {
					...newPages[pageIndex],
					page_fields: [...newFields],
				};
				setCurrentForm((prevState: any) => ({ ...prevState, pages: newPages }));
			}
		}
	};

	const removeLastField = () => {
		if (pages) {
			let newDroppedItems = pages.slice();
			let newFields: Array<IFields> = [];
			if (newDroppedItems[currentPage].page_fields)
				newFields = newDroppedItems[currentPage].page_fields!.slice();
			newFields.pop();
			newDroppedItems[currentPage] = {
				...newDroppedItems[currentPage],
				page_fields: [...newFields],
			};
			setCurrentForm((prevState: any) => ({ ...prevState, pages: newDroppedItems }));
			setNewField(null);
			setFieldFormOpen(false);
		}
	};

	const addNewPage = () => {
		if (pages && currentForm && currentForm.uuid) {
			const newDroppedItems = pages.slice();
			newDroppedItems.push({
				...emptyPage,
				order: newDroppedItems.length,
				form_id: currentForm?.uuid,
			});
			setCurrentForm((prevState: any) => ({ ...prevState, pages: newDroppedItems }));
			setCurrentPage(newDroppedItems.length - 1);
			setCurrentPageForm(newDroppedItems[newDroppedItems.length - 1]);
		}
	};

	const removeLastPage = () => {
		if (currentForm && pages) {
			const newDroppedItems = pages.slice();
			newDroppedItems.pop();
			setCurrentForm((prevState: any) => ({ ...prevState, pages: newDroppedItems }));
			setCurrentPage(0);
		}
	};

	const savePage = () => {
		if (currentPageForm) {
			if (currentPageForm.uuid) {
				toast.promise(
					updatePage(currentPageForm.uuid, currentPageForm)
						.then((res) => {
							if (allForms && currentForm && currentForm.id) {
								let newAllForms = allForms.slice();
								let index = newAllForms.findIndex(
									(form) => currentForm.id === form.id,
								);
								newAllForms[index] = res;
								setAllForms(newAllForms);
							}
							setCurrentForm(res);
							setCurrentPageForm(null);
						})
						.catch((error) => console.log(error)),
					{
						loading: t('updatingPage'),
						success: t('successUpdatingPage'),
						error: t('errorUpdatingPage'),
					},
				);
			} else {
				toast.promise(
					createPage(currentPageForm)
						.then((res) => {
							if (allForms && currentForm && currentForm.id) {
								let newAllForms = allForms.slice();
								let index = newAllForms.findIndex(
									(form) => currentForm.id === form.id,
								);
								newAllForms[index] = res;
								setAllForms(newAllForms);
							}
							setCurrentForm(res);
							setCurrentPageForm(null);
						})
						.catch((error) => console.log(error)),
					{
						loading: t('creatingPage'),
						success: t('successCreatingPage'),
						error: t('errorCreatingPage'),
					},
				);
			}
		}
	};

	const deletePage = () => {
		if (currentPageForm && currentPageForm.uuid) {
			toast.promise(
				removePage(currentPageForm.uuid)
					.then((res) => {
						if (!(res instanceof AxiosError)) {
							setCurrentForm(res);
							setCurrentPage(0);
							setCurrentPageForm(null);
							getAllForms();
						}
					})
					.catch((error) => console.log(error)),
				{
					loading: t('removingPage'),
					success: t('successRemovingPage'),
					error: t('errorRemovingPage'),
				},
			);
		}
	};

	const saveField = () => {
		if (pages && newField && pages[currentPage] && pages[currentPage].page_fields) {
			if (newField.id === undefined) {
				let field = { ...newField };
				if (pages[currentPage].uuid) {
					field.page_id = pages[currentPage].uuid;
					field.form_uuid = currentForm!.uuid;
					createFormField(field).then((res: IPages) => {
						let newPages = [...pages];
						newPages[currentPage] = res;
						if (allForms && currentForm) {
							let newCurrentForm: Form = { ...currentForm };
							newCurrentForm.pages = newPages;
							let i = allForms.findIndex((form) => form.uuid === currentForm!.uuid);
							let newAllForms: Form[] = [...allForms];
							newAllForms[i] = newCurrentForm;
							setAllForms(newAllForms);
							setCurrentForm(newCurrentForm);
						}
					});
					const currentPageFields = pages[currentPage].page_fields;
					if (currentPageFields && currentPageFields.length > 0) {
						currentPageFields[currentPageFields.length - 1] = newField;
						setNewField(null);
						setFormEditMode('page');
						setFieldFormOpen(false);
					}
				}
			} else {
				updateField(newField.id, newField).then((res: IFields[]) => {
					let newPages = [...pages];
					newPages[currentPage].page_fields = res;
					setCurrentForm((prevState: any) => ({ ...prevState, pages: newPages }));
					getAllForms();
				});
				setNewField(null);
				setFormEditMode('page');
				setFieldFormOpen(false);
			}
		}
	};

	const changePageOrder = (data: IPages) => {
		changeOrder(data)
			.then((res) => {
				if (res) {
					setCurrentForm((prevState: any) => ({ ...prevState, pages: res }));
				}
			})
			.catch((error) => console.log(error));
	};

	const formDelete = (item: Form) => {
		if (item.uuid) {
			toast
				.promise(deleteForm(item.uuid), {
					loading: t('formDelete'),
					success: t('successFormDelete'),
					error: t('errorFormDelete'),
				})
				.then((res) => {
					setEditWindowsOpen({ open: false, type: '' });
					setFormEditMode('');
					getAllForms();
				});
		}
	};

	const getIFrame = () => {
		return (currentForm && currentForm.uuid) ? renderToStaticMarkup(<iframe className="VPreview__IFrame__full" src={FRONTEND_BASE_PATH + "/process/" + currentForm.uuid}/>) : ""
	}

	const copyToClipboard  = () => {
		navigator.clipboard.writeText(
			getIFrame()
		);
		toast.success(
			t('successCopyClipboard')
		)
	}

	const StyledBreadcrumb = styled(Chip)(({theme}) => {
		const backgroundColor =
			theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[800];
		return {
			backgroundColor,
			height: theme.spacing(3),
			color: theme.palette.text.primary,
			fontWeight: theme.typography.fontWeightRegular,
			'&:hover, &:focus': {
				backgroundColor: emphasize(backgroundColor, 0.06),
			},
			'&:active': {
				boxShadow: theme.shadows[1],
				backgroundColor: emphasize(backgroundColor, 0.12),
			},
		};
	}) as typeof Chip;

	const tableFunctions: ITableFunctions = {
		duplicateForm: {
			action: async (form_uuid: string) => {
				await duplicateForm(form_uuid).then(res => {
					if(!(res instanceof AxiosError)) {
						getAllForms()
						toast.success( t('successDuplicatingForm'))
					}
				});
			},
			actionName: t('duplicate'),
			argument: 'uuid',
			icon: 'FileCopy',
		},
	};

	return (
		<PageWrapper>
			<Page container='fluid'>
				{allForms && (
					<EErrorBoundary msg={"Something went wrong while showing data..."}>
						<DataTable<Form>
							columns={FormColumns}
							emptyValue={emptyForm}
							getAll={findAllForms}
							post={createForm}
							isFluid={true}
							name={name}
							openWindows={setEditWindowsOpen}
							editItem={setCurrentForm}
							delete={formDelete}
							data={allForms || undefined}
							setData={setAllForms}
							tableFunctions={tableFunctions}
							toggleActive={toggleActive}
						/>
					</EErrorBoundary>
				)}

				<Backdrop
					sx={{
						color: '#fff',
						zIndex: 1035,
						backdropFilter: 'blur(5px)',
					}}
					open={editWindowsOpen.open}
					onClick={() => false}></Backdrop>

				{editWindowsOpen.open && editWindowsOpen.type !== 'add' && (
					<DropWindow
						setOpen={setEditWindowsOpen}
						isOpen={editWindowsOpen}
						placement={'start'}
						name={'Vorschau'}>
						<>
							{currentForm && currentForm.pages && pages && currentPage >= 0 && (
								<EErrorBoundary msg={"Something went wrong showing the your preview..."}>
									<VForm
										isCreator={true}
										accepts={'DRAGGABLE_ITEM'}
										onDrop={handleDrop}
										addNewItem={addNewItem}
										currentPage={currentPage}
										removeItem={removeItem}
										currentForm={currentForm}
										setCurrentForm={setCurrentForm}
										setNewField={setNewField}
										setFieldFormOpen={setFieldFormOpen}
										getAllForms={getAllForms}
									/>
								</EErrorBoundary>
							)}
						</>
					</DropWindow>
				)}
				{pages && (
					<DragWindow
						setOpen={setEditWindowsOpen}
						isOpen={editWindowsOpen}
						placement={'end'}
						name={
							currentForm && currentForm.uuid !== ''
								? t('edit') +
								  (formEditMode && ' - ' + t(`formEdit.${formEditMode}`))
								: t('create')
						}
						subTitle={
							editWindowsOpen.type !== 'add' ? (
								<Breadcrumbs
									aria-label='breadcrumb'
									separator={<NavigateNextIcon fontSize='small' />}
									sx={{
										color: themeStatus === 'dark' ? 'white' : 'black',
										fontSize: 10,
									}}>
									{formEditMode !== '' && (
										<StyledBreadcrumb
											component='a'
											href='#'
											onClick={() => setFormEditMode('')}
											label={t('edit')}
											icon={<HomeIcon fontSize='small' />}
										/>
									)}
									{formEditMode && (
										<StyledBreadcrumb
											component='a'
											href='#'
											onClick={() =>
												setFormEditMode(
													[
														'page-field',
														'page-style',
														'page-image',
														'page',
													].includes(formEditMode)
														? 'page'
														: formEditMode,
												)
											}
											label={
												[
													'page-field',
													'page-style',
													'page-image',
													'page',
												].includes(formEditMode)
													? t(`formEdit.page`)
													: t(`formEdit.${formEditMode}`)
											}
										/>
									)}
									{['page-field', 'page-style', 'page-image'].includes(
										formEditMode,
									) && (
										<StyledBreadcrumb
											component='a'
											href='#'
											label={t(`formEdit.${formEditMode}`)}
										/>
									)}
								</Breadcrumbs>
							) : (
								<></>
							)
						}>
						<div className='NestedNav row g-4'>
							{formEditMode === '' && currentForm && (
								<>
									<>
										<FormForm
											setForm={setCurrentForm}
											form={currentForm}
											saveForm={() => {}}
										/>
										<Button
											color='success'
											onClick={() => {
												saveForm();
											}}>
											<Save/>
										</Button>
									</>
									{currentForm.uuid !== '' && (
										<>
											<Button
												color='dark'
												onClick={() => {
													setFormEditMode('page');
												}}>
												{t('pages')} <ArrowForwardIos />
											</Button>
											<Button
												color='dark'
												onClick={() => {
													setFormEditMode('style');
												}}>
												{t('styles')} <ArrowForwardIos />
											</Button>
											<Button
												color='dark'
												onClick={() => {
													setFormEditMode('image');
												}}>
												{t('uploadBackground')} <ArrowForwardIos />
											</Button>
											<Button
												color='dark'
												onClick={() => {
													setFlowOpen(true)
												}}>
												{t('editPageFlow')} <ArrowForwardIos />
											</Button>
											<Button
												color='dark'
												onClick={() => {
													setFormEditMode('template');
												}}>
												{t('confirmationEmail')} <ArrowForwardIos />
											</Button>
											<div
												className="NestedNav__iframe"
												onClick={() => {
													copyToClipboard()
												}}
											>
												<p 	className="NestedNav__iframe__text">{getIFrame()}</p>
												<div className="NestedNav__iframe__copy">
													{t('copyClipboard')} <CopyAll/>
												</div>
											</div>
										</>
									)}
								</>
							)}

							{formEditMode === 'page' && pages && !currentPageForm && (
								<div className='NestedNav__nav-wrapper'>
									<Button
										color='dark'
										onClick={() => {
											setFormEditMode('');
										}}>
										<ArrowBackIos />
									</Button>
									{pages.map((page: IPages, pageIndex: number, pageArray) => {

										const isDisabled = pageIndex === pageArray.length - 1  || pageIndex === pageArray.length - 2 || pageArray.length === 3

										return (
											<div className='NestedNav__nav-button' key={pageIndex}>
												<div className='NestedNav__nav-button__order'>
													<Button
														color='dark'
														className={classNames('top-btn')}
														isDisable={pageIndex === 0 || isDisabled}
														onClick={() => {
															let newData = {
																...page,
																order: page.order - 1,
															};
															delete newData.page_fields;
															changePageOrder(newData);
														}}>
														<KeyboardArrowUp />
													</Button>
													<Button
														color='dark'
														className={classNames('top-btn')}
														isDisable={isDisabled}
														onClick={() => {
															let newData = {
																...page,
																order: page.order + 1,
															};
															delete newData.page_fields;
															changePageOrder(newData);
														}}>
														<KeyboardArrowDown />
													</Button>
												</div>
												<div
													className="NestedNav__nav-button__page-title"
													onClick={() => {
														setCurrentPage(pageIndex);
														setCurrentPageForm(page);
													}}>
													{
														page.title.length > 30 ? (
															<p className="NestedNav__nav-button__page-title__title">{page.title}</p>
														) : (
															<>{page.title}</>
														)
													}

												</div>
												<div className="NestedNav__nav-button__go-to-page">
													<span
														onClick={() => {
															setCurrentPage(pageIndex);
															setCurrentPageForm(page);
														}}
													>
														&gt;
													</span>
												</div>

											</div>
										);
									})}
									{pages && (
										<>
											<div className='NestedNav__nav-wrapper__action-buttons'>
												<Button
													color={'success'}
													onClick={() => {
														addNewPage();
													}}>
													+
												</Button>
											</div>
										</>
									)}
								</div>
							)}

							{currentForm && currentPageForm && !newField && (
								<>
									<PageForm
										currentForm={currentForm}
										setPageForm={setCurrentPageForm}
										pageForm={currentPageForm}
										removeLastPage={removeLastPage}
										removeLastItem={removeLastField}
										formEditMode={formEditMode}
										setFormEditMode={setFormEditMode}
										setCurrentForm={setCurrentForm}
										savePage={savePage}
										deletePage={deletePage}
										getAllForms={getAllForms}
										currentPage={currentPage}
									/>
								</>
							)}

							{formEditMode === 'style' && (
								<>
									{currentForm && (
										<StyleForm
											currentForm={currentForm}
											setCurrentForm={setCurrentForm}
											handleFormUpdate={saveFormStyle}
											formEditMode={formEditMode}
											setFormEditMode={setFormEditMode}
										/>
									)}
								</>
							)}

							{formEditMode === 'image' && currentForm && (
								<ImageForm
									getAllForms={getAllForms}
									setFormEditMode={setFormEditMode}
									formEditMode={formEditMode}
									currentForm={currentForm}
									setCurrentForm={setCurrentForm}
									formObject='Form'
									companyId={currentForm.company_id}
								/>
							)}

							{formEditMode === 'template' && currentForm && (
								<TemplateForm
									setFormEditMode={setFormEditMode}
									currentForm={currentForm}
									setCurrentForm={setCurrentForm}
									formObject='Form'
									saveForm={saveForm}
								/>
							)}

							<Modal
								isOpen={fieldFormOpen}
								setIsOpen={setFieldFormOpen}
								isStaticBackdrop={true}
								isCentered={true}
								size={"lg"}
							>
								<ModalHeader>
									Field Settings
									<Button
										onClick={() => {
											if (newField && newField.id) {
												setNewField(null);
												setFieldFormOpen(false);
												setFormEditMode('page-field');
											} else {
												removeLastField();
											}
										}}>
										<CancelRounded />
									</Button>
								</ModalHeader>
								<ModalBody>
									<>
										{newField &&
											currentForm &&
											currentForm.pages &&
											currentForm.pages[currentPage] && (
												<FieldForm
													setField={setNewField}
													field={newField}
													saveField={saveField}
													removeLastField={removeLastField}
													allFields={
														currentForm.pages[currentPage].page_fields
													}
													companyId={currentForm.company_id}
												/>
											)
										}
										{
											newField && newField.id && (
												<div style={{margin: "2rem 0", padding: "1rem", background: "#ffcf52", borderRadius: "25px", textAlign: "center"}}>
													{t('warning')} <br/> {t('warningPageFlow')}
												</div>
											)
										}
									</>
								</ModalBody>
								<ModalFooter>
									<div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
										<Button
											color='warning'
											onClick={() => {
												if (newField && newField.id) {
													setNewField(null);
													setFieldFormOpen(false);
													setFormEditMode('page');
												} else {
													setFormEditMode('page-field');
													removeLastField();
												}
											}}>
											{newField && newField.id ? t('close') : t('remove')}
										</Button>
										<Button
											color='success'
											onClick={() => {
												saveField();
											}}>
											{t('save')}
										</Button>
									</div>
								</ModalFooter>
							</Modal>
						</div>
					</DragWindow>
				)}
				<Modal
					isOpen={flowOpen}
					setIsOpen={setFlowOpen}
					fullScreen={true}
					isCentered={true}
					isStaticBackdrop={true}
				>
					<ModalHeader style={{display: "flex", justifyContent:"end"}}>
						<Button
							style={{
								alignItems: 'end',
								display: 'flex',
								justifyContent: 'flex-end',
								flexDirection: 'row',
								alignSelf: 'flex-end',
							}}
							onClick={() => {
								setFlowOpen(false)
							}}>
							<CancelRounded/>
						</Button>
					</ModalHeader>
					<ModalBody>
						<EErrorBoundary msg={"Something went wrong showing the Pageflow..."}>
							<div style={{width: '100%', height: '100%', justifyContent: "center", display: "flex"}}>
								{
									currentForm && (
										<CustomFlow form={currentForm} getAllForms={getAllForms} setFlowOpen={setFlowOpen}/>
									)
								}
							</div>
						</EErrorBoundary>
					</ModalBody>
				</Modal>
			</Page>
		</PageWrapper>
	);
};

export default FormsTable;
