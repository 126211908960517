import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import {downloadString, getPictureUrl} from '../helpers/helpers';
import useDarkMode from '../hooks/useDarkMode';
import Button from './bootstrap/Button';
import {FieldType} from './editWindow';
import {KeyboardArrowDown, KeyboardArrowUp} from '@mui/icons-material';
import toast from 'react-hot-toast';
import Dropdown, {DropdownItem, DropdownMenu, DropdownToggle} from './bootstrap/Dropdown';
import {deleteAsTemplate, saveAsTemplate as saveAsTemplateFunc,} from '../models/forms/forms.repository';
import {downloadCSV} from '../models/data/data.repository';
import {AxiosError} from 'axios';
import {ITableFunctions} from '../frontend/src/Interfaces/IGeneral';
import {Switch} from '@mui/material';
import columns from "./icon/bootstrap/Columns";
import moment from "moment";

export interface Columns {
	id?: any;
	displayed?: boolean;
	showImage?: boolean;
	name: string;
	dataName: string;
	fieldType?: FieldType;
	valuesToBeSelected?: { value: string | number; text: string }[];
	prefix?: string;
	suffix?: string;
	isInternalLink?: boolean;
}

const Table = (props: {
	hideEdit: boolean;
	hideDelete: boolean;
	hideOrder: boolean;
	data: any[];
	edit: (id: any) => void;
	columns: Columns[];
	delete: (id: any) => void;
	changeOrder?: (data: any) => void;
	showActions?: boolean;
	showInModal?: (dat: any) => void;
	tableFunctions?: ITableFunctions;
	toggleActive?: (data: any) => void | undefined;
	linkIcon?: any;
}) => {
	const { themeStatus, darkModeStatus } = useDarkMode();
	const { t } = useTranslation(['translation', 'menu']);
	const [formData, setFormData] = useState(props.data);

	useEffect(() => {
		if (props.data) {
			setFormData(props.data);
		}
	}, [props.data]);

	function getValueForColumn(column: Columns, data: any, colindex: number) {

		switch (column.fieldType) {
			case FieldType.Switch:
				if (props.toggleActive) {
					return (
						<td key={column.dataName + 'column' + colindex}>
							<Switch
								checked={data[column.dataName]}
								size={'small'}
								onChange={(e) => {
									e.target.checked = !e.target.checked;
									if (props.toggleActive) {
										props.toggleActive(data);
									}
								}}
							/>
						</td>
					);
				} else {
					return (
						<td key={column.dataName + 'column' + colindex}>
							<div className='d-flex align-items-center'>
								<span
									className={classNames(
										'border border-2',
										[`border-${themeStatus}`],
										'rounded-circle',
										`bg-${!!data[column.dataName] ? 'success' : 'danger'}`,
										'p-2 me-2',
									)}
								/>
							</div>
						</td>
					);
				}

			case FieldType.Image:
				return (
					<td key={column.dataName + 'column' + colindex}>
						<img
							style={{ height: 60 }}
							src={getPictureUrl(data[column.dataName])}></img>
					</td>
				);

			case FieldType.Link:
				return <>{returnLink(column, data, colindex)}</>;

			default:
				if (column.showImage === true) {
					return (
						<td key={column.dataName + 'column' + colindex}>
							<img
								style={{ height: 60 }}
								src={getPictureUrl(data[column.dataName])}></img>
						</td>
					);
				} else {
					if (!column.isInternalLink) {
						return (
							<td key={column.dataName + 'column' + colindex}>
								{column.prefix ? column.prefix : ''}
								{data[column.dataName]}
								{column.suffix ? column.suffix : ''}
							</td>
						);
					} else {
						return <>{returnLink(column, data, colindex)}</>;
					}
				}
		}
	}

	const returnLink = (column: Columns, data: any, colindex: number) => {
		return (
			<td key={column.dataName + 'column' + colindex}>
				<a
					href={
						(column.prefix ? column.prefix : '') +
						'' +
						data[column.dataName] +
						'' +
						(column.suffix ? column.suffix : '')
					}>
					{column.prefix ? column.prefix : ''}
					{data[column.dataName]}
					{column.suffix ? column.suffix : ''}
				</a>
			</td>
		);
	};

	const handleCSVDownload = async (downloadLink: string, dataName: string) => {
		await downloadCSV(downloadLink).then((res) => {
			if (res instanceof AxiosError) {
				if (res.response) {
					toast.error(res.response.data.message);
				} else {
					toast.error(t('error'));
				}
			} else {
				downloadString(res, 'csv', dataName + '.csv');
				toast.success(t('successfullyCreated'));
			}
		});
	};

	return (
		<table className='table table-modern'>
			<thead>
				<tr>
					{props.columns?.map((column, colindex) => {
						if (column.displayed !== false) {
							return (
								<th key={column.dataName + '' + colindex}>
									{t(column.name)}
								</th>
							);
						}
					})}

					{(!props.hideDelete || !props.hideEdit || props.showActions) && (
						<th datatype='action' key={'AKTION'} align='center' className='text-center'>
							{t('action')}
						</th>
					)}
					{!props.hideOrder && props.changeOrder !== undefined && <th>{t('move')}</th>}
				</tr>
			</thead>
			<tbody>
				{formData.map((data, dataIndex, dataArray) => {
					let linkButton = '';
					let moveToPage = '';
					let saveAsTemplate = false;
					let actionItemsCount = 0;
					let download = '';
					let dataN = '';
					let openInModal: null | string = null;

					return (
						<tr key={data.id + '_' + dataIndex}
								style={{
									height: "8rem"
								}}
						>
							{props.columns?.map((column, colindex) => {
								if (column.displayed !== false) {
									return getValueForColumn(column, data, colindex);
								}

								if (
									column.fieldType === FieldType.MoveToPage &&
									column.dataName === 'form_link'
								) {
									moveToPage =
										(column.prefix ? column.prefix : '') +
										'' +
										data[column.dataName] +
										'' +
										(column.suffix ? column.suffix : '');
								}

								if (
									column.fieldType === FieldType.Link &&
									!column.displayed &&
									column.dataName === 'form_link'
								) {
									linkButton =
										(column.prefix ? column.prefix : '') +
										'' +
										data[column.dataName] +
										'' +
										(column.suffix ? column.suffix : '');
								}

								if (column.fieldType === FieldType.ShowInModal) {
									if (props.showInModal) {
										openInModal = data[column.dataName];
									}
								}

								if (
									column.fieldType === FieldType.Link &&
									!column.displayed &&
									column.dataName === 'save_as_template'
								) {
									saveAsTemplate = data[column.dataName] ?? false;
								}

							if (
								column.fieldType === FieldType.Download &&
								!column.displayed &&
								column.dataName === 'uuid'
							) {
								download =
									(column.prefix ? column.prefix : '') +
									data[column.dataName];
								dataN = data[column.dataName];
							}

								actionItemsCount =
									(linkButton !== '' ? 1 : 0) +
									(moveToPage ? 1 : 0) +
									(props.hideDelete ? 0 : 1);
							})}
							{props.showActions === true &&
								(props.hideDelete === false ||
									props.hideEdit === false ||
									linkButton ||
									moveToPage ||
									openInModal) && (
									<td>
										<div
											style={{
												height: "100%",
												display: 'flex',
												flexDirection: 'row',
												gap: 5,
												justifyContent: 'center',
											}}
										>
										{!props.hideEdit && (
											<Button
												color='dark'
												isLight
												icon='Edit'
												tag='a'
												onClick={() => props.edit(data)}
											/>
										)}

										{download && (
											<Button
												icon='Download'
												color='dark'
												isLight
												onClick={() => {
													handleCSVDownload(download, dataN);
												}}></Button>
										)}

										{props.showInModal && openInModal && (
											<Button
												isOutline={!darkModeStatus}
												color='dark'
												isLight={darkModeStatus}
												className={classNames('text-nowrap', {
													'border-light': !darkModeStatus,
												})}
												icon={props.linkIcon ? props.linkIcon : 'eye'}
												onClick={() => {
													if (props.showInModal) {
														props.showInModal(openInModal);
													}
												}}></Button>
										)}

										{actionItemsCount > 1 ? (
											<Dropdown>
												<DropdownToggle hasIcon={false}>
													<Button
														isLight
														icon='MoreVert'
														color={'dark'}
													/>
												</DropdownToggle>
												<DropdownMenu isAlignmentEnd>
													{linkButton && (
														<DropdownItem>
															<Button
																icon='Eye'
																tag='a'
																href={linkButton}
																target='_blank'
																key={'visit-1'}>
																{t('visit')}
															</Button>
														</DropdownItem>
													)}
													{moveToPage && (
														<DropdownItem>
															<Button
																tag='a'
																icon='Eye'
																to={moveToPage}>
																{t('visit')}
															</Button>
														</DropdownItem>
													)}
													{linkButton && (
														<DropdownItem>
															<Button
																icon='Link'
																onClick={() => {
																	toast.success(
																		t('copyLinkSuccess'),
																		{
																			position: 'top-center',
																		},
																	);
																	navigator.clipboard.writeText(
																		linkButton,
																	);
																}}>
																{t('copyLink')}
															</Button>
														</DropdownItem>
													)}
													{saveAsTemplate ? (
														<DropdownItem>
															<Button
																icon='Star'
																className='star-button'
																onClick={() => {
																	deleteAsTemplate({
																		formUuid: data.uuid,
																	}).then((response) => {
																		if (response) {
																			setFormData(
																				(prevData) =>
																					prevData.map(
																						(
																							item,
																							index,
																						) => {
																							if (
																								index ===
																								dataIndex
																							) {
																								return {
																									...item,
																									save_as_template:
																										false,
																								};
																							}
																							return item;
																						},
																					),
																			);

																			toast.success(
																				t(
																					'deleteFromTemplate',
																				),
																				{
																					position:
																						'top-center',
																				},
																			);
																		} else {
																			toast.error(
																				t(
																					'savedErrorTemplate',
																				),
																				{
																					position:
																						'top-center',
																				},
																			);
																		}
																	});
																}}>
																{t('deleteFromTemplate')}
															</Button>
														</DropdownItem>
													) : (
														<DropdownItem>
															<Button
																icon='StarBorder'
																onClick={() => {
																	saveAsTemplateFunc({
																		name: data.name,
																		formUuid: data.uuid,
																	}).then((response) => {
																		if (response) {
																			setFormData(
																				(prevData) =>
																					prevData.map(
																						(
																							item,
																							index,
																						) => {
																							if (
																								index ===
																								dataIndex
																							) {
																								return {
																									...item,
																									save_as_template:
																										true,
																								};
																							}
																							return item;
																						},
																					),
																			);
																			toast.success(
																				t(
																					'savedAsTemplate',
																				),
																				{
																					position:
																						'top-center',
																				},
																			);
																		} else {
																			toast.error(
																				t(
																					'savedErrorTemplate',
																				),
																				{
																					position:
																						'top-center',
																				},
																			);
																		}
																	});
																}}>
																{t('saveAsTemplate')}
															</Button>
														</DropdownItem>
													)}
													{props.tableFunctions &&
														Object.keys(props.tableFunctions).map(
															(functionKey, keyIndex) => {
																const tablefunction : any = props.tableFunctions![functionKey];

																let renderButton = true

																if(tablefunction.applyTo !== undefined && data[tablefunction.applyTo.attribute] !== undefined) {
																	renderButton = data[tablefunction.applyTo.attribute] === tablefunction.applyTo.value
																}

																return renderButton ? (
																	<DropdownItem
																		key={keyIndex + functionKey}>
																		<Button
																			icon={tablefunction.icon}
																			onClick={() => {
																				tablefunction.action(
																					data[
																						tablefunction
																							.argument
																						],
																				);
																			}}>
																			{tablefunction.actionName}
																		</Button>
																	</DropdownItem>
																) : <></>;
															},
														)}
													{!props.hideDelete && (
														<>
															<DropdownItem isDivider />
															<DropdownItem>
																<Button
																	style={{color: "var(--bs-red)"}}
																	icon='Delete'
																	onClick={() => {
																		props.delete(data);
																	}}>
																	{t('delete')}
																</Button>
															</DropdownItem>
														</>
													)}
												</DropdownMenu>
											</Dropdown>
										) : (
											<>
												{moveToPage && (
													<Button
														color='dark'
														isLight
														tag='a'
														icon={
															props.linkIcon ? props.linkIcon : 'eye'
														}
														to={moveToPage}
													/>
												)}
												{linkButton && (
													<Button
														color='dark'
														isLight
														icon='Link'
														onClick={() => {
															toast.success(t('copyLinkSuccess'), {
																position: 'top-center',
															});
															navigator.clipboard.writeText(
																linkButton,
															);
														}}
													/>
												)}
												{props.tableFunctions &&
													Object.keys(props.tableFunctions).map(
														(functionKey, keyIndex) => {

															const tablefunction : any = props.tableFunctions![functionKey];

															let renderButton = true

															if(tablefunction.applyTo !== undefined && data[tablefunction.applyTo.attribute] !== undefined) {
																renderButton = data[tablefunction.applyTo.attribute] === tablefunction.applyTo.value
															}

															return renderButton ? (
																	<Button
																		color={"dark"}
																		key={keyIndex + functionKey}
																		icon={tablefunction.icon}
																		onClick={() => {
																			tablefunction.action(
																				data[tablefunction.argument],
																			);
																		}}/>
															) : <></>;
														},
													)}
												{!props.hideDelete && (
													<Button
														color='dark'
														isLight
														icon='Delete'
														onClick={() => {
															props.delete(data);
														}}
													/>
												)}
											</>
										)}
										</div>
									</td>
								)}
							{props.changeOrder !== undefined && !props.hideOrder && (
								<td>
									<div>
										<Button
											isOutline={!darkModeStatus}
											color='dark'
											isLight={darkModeStatus}
											className={classNames(
												'text-nowrap',
												{
													'border-light': !darkModeStatus,
												},
												'top-btn',
											)}
											isDisable={dataIndex === 0}
											onClick={() => {
												let newData = { ...data, order: data.order - 1 };
												delete newData.forms;
												delete newData.page_fields;
												delete newData.page_styles;
												if (props.changeOrder) props.changeOrder(newData);
											}}>
											<KeyboardArrowUp />
										</Button>
										<Button
											isOutline={!darkModeStatus}
											color='dark'
											isLight={darkModeStatus}
											className={classNames(
												'text-nowrap',
												{
													'border-light': !darkModeStatus,
												},
												'low-btn',
											)}
											isDisable={dataIndex === formData.length - 1}
											onClick={() => {
												let newData = { ...data, order: data.order + 1 };
												delete newData.forms;
												delete newData.page_fields;
												delete newData.page_styles;
												if (props.changeOrder) props.changeOrder(newData);
											}}>
											<KeyboardArrowDown />
										</Button>
									</div>
								</td>
							)}
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

export default Table;
