import React, {ReactElement, SetStateAction, useEffect, useRef, useState} from 'react';
import { IFields } from '../../../Interfaces/IGeneral';
import './MPage.scss';
import {
	Box,
	Button
} from '@mui/material';
import EMessage from '../../Elements/EMessage/EMessage';
import { IPages } from '../../../Interfaces/IPages';
import EButton from '../../Elements/EButton/EButton';
import { emptyField } from '../../../../../models/pages/pages.module';
import { EditRounded } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { IDataCreate } from '../../../../../models/data/data.module';
import {
	updateFieldPosition,
	uploadFormDataFile,
} from '../../../../../models/forms/forms.repository';
import toast from 'react-hot-toast';
import DragDropWrapper from './Field';
import CustomFileField from '../../Elements/CustomFile';
import { useTranslation } from 'react-i18next';
import AppointmentPicker from "../../../../../components/AppointmentPicker/AppointmentPicker";
import ETextFieldForm from "../../Elements/ETextFieldForm/ETextFieldForm"
import ENumberFieldForm from "../../Elements/ENumberFieldForm/ENumberFieldForm";
import EEmailFormField from "../../Elements/EEmailFormField/EEmailFormField";
import ESwitchFormField from "../../Elements/ESwitchFormField/ESwitchFormField";
import {renderByDependencyOperator} from "../../../Utils/HelperFunctions";
import EMultiselectForm from "../../Elements/EMultiSelect/EMultiselectForm";
import ESingleselectForm from "../../Elements/ESingleselectForm/ESingleselectForm";
import ESliderForm from "../../Elements/ESliderForm/ESliderForm";
import ERangeFormField from "../../Elements/ERangeFormField/ERangeFormField";
import EErrorBoundary from "../../EErrorBoundary/EErrorBoundary";
import EMultiSelectBoxFormField from "../../Elements/EMultiSelectBoxFormField/EMultiSelectBoxFormField";
import ESingleSelectBoxFormField from "../../Elements/ESingleSelectBoxFormField/ESingleSelectBoxFormField";
import moment from "moment";
import ESeperator from "../../Elements/ESeperator/ESeperator";

interface IProps {
	pageInfo: IPages;
	form_uuid: string;
	pageNumber: number;
	currentPage: number;
	totalPages: number;
	setPagesValid: React.Dispatch<SetStateAction<Array<boolean>>>;
	pagesValid: Array<boolean>;
	message: { message: string; type: 'success' | 'error' } | null;
	setMessage: React.Dispatch<
		SetStateAction<{ message: string; type: 'success' | 'error' } | null>
	>;
	onDrop?: (item: IFields, pageIndex: number) => void;
	addNewItem?: (item: IFields, pageIndex: number) => void;
	removeItem?: (arrayIndex: number, pageIndex: number) => void;
	accepts?: any;
	isCreator: boolean;
	setNewField?: React.Dispatch<SetStateAction<IFields | null>>;
	setFieldFormOpen?: React.Dispatch<SetStateAction<boolean>>;
	data?: Array<IDataCreate>;
	setData?: React.Dispatch<SetStateAction<Array<IDataCreate[]> | null>>;
	uploadedFilePaths?: Array<{ [key: string]: string }> | null;
	setUploadedFilePaths?: React.Dispatch<SetStateAction<Array<{ [key: string]: string }> | null>>;
	allData?: Array<Array<IDataCreate>>
	history: number[]
}

interface IFormFieldFormProps {
	field: IFields,
	fieldIndex: number,
	data?: Array<IDataCreate>,
	setData?: React.Dispatch<SetStateAction<Array<IDataCreate[]> | null>>;
	pageNumber: number

}

const MPage = (props: IProps) => {
	const [fields, setFields] = useState<IPages | null>(null);
	const [hoveredDropTarget, setHoveredDropTarget] = useState<number | null>(null);
	const { t } = useTranslation(['translation']);

	const scrollableRef = useRef<null | HTMLDivElement>(null);

	useEffect(() => {
			if(scrollableRef.current !== null) {
				scrollableRef.current?.scrollTo(0,0);
			}
	}, [props.currentPage]);

	useEffect(() => {
		if (props.pageInfo) {
			initValues();
		}
	}, [props.pageInfo, props.pageNumber, props.pageInfo.page_fields]);

	useEffect(() => {
		let pagesValid = props.pagesValid.slice();
		if (props.data && fields && fields.page_fields) {
			pagesValid[props.currentPage] = validateRequiredFields();
			props.setPagesValid(pagesValid);
		} else {
			pagesValid[props.currentPage] = false;
			props.setPagesValid(pagesValid);
		}
	}, [fields, props.data, props.currentPage]);

	const moveField = (dragIndex: number, hoverIndex: number, updateBackend = false) => {
		if (fields && fields.page_fields) {
			const newFields = [...fields.page_fields];
			const dragField = newFields.splice(dragIndex - 1, 1)[0];

			let adjustedHoverIndex = hoverIndex - 1;
			if (dragIndex < hoverIndex) {
				adjustedHoverIndex = hoverIndex - 1;
			}

			newFields.splice(adjustedHoverIndex, 0, dragField);

			newFields.forEach((field, index) => {
				field.order = index + 1;
			});

			setFields((prevFields: any) => {
				return { ...prevFields, page_fields: newFields };
			});

			if (updateBackend) {
				updateFieldPosition({ formId: fields.uuid, updatedFields: newFields });
			}
		}
	};

	const initValues = () => {
		if (props.pageInfo) {
			let newValues: IPages = { ...props.pageInfo };
			if (newValues.page_fields) {
				newValues.page_fields.map((field, fieldIndex) => {
					switch (field.dataType) {
						case 'Email':
						case 'SingleSelect':
						case 'SingleSelectBox':
						case 'TextField':
							if (newValues.page_fields)
								newValues.page_fields[fieldIndex] = { ...field, values: '' };
							break;
						case 'Switch':
							if (newValues.page_fields)
								newValues.page_fields[fieldIndex] = { ...field, values: 'false' };
							break;
						case 'Number':
							if (newValues.page_fields)
								newValues.page_fields[fieldIndex] = { ...field, values: '' };
							break;
						case 'MultiSelect':
						case 'MultiSelectBox':
							let ar: any[] = [];
							if (newValues.page_fields)
								newValues.page_fields[fieldIndex] = { ...field, values: ar };
							break;
						case 'Range':
							if (newValues.page_fields) {
								let jsonar: any[] = [
									JSON.parse(field.valuesToBeSelected).min,
									JSON.parse(field.valuesToBeSelected).steps,
								];
								newValues.page_fields[fieldIndex] = { ...field, values: jsonar };
							}
							break;
					}
				});
			}
			setFields(newValues);
		}
	};

	const handleHover = (index: number) => {
		setHoveredDropTarget(index);
	};

	const handleDragEnd = () => {
		setHoveredDropTarget(null);
	};

	const validateEmail = (e: any) => {
		const eRegEx = new RegExp('^[a-zA-Z0-9|.]+@[a-zA-Z0-9]+\\.[A-Za-z]+$');

		if (!eRegEx.test(e.target.value) && e.target.value !== '') {
			props.setMessage({
				message: 'This Email is not Valid',
				type: 'error',
			});
			let validArray = props.pagesValid.slice();

			validArray[props.currentPage] = false;

			props.setPagesValid(validArray);
		} else {
			props.setMessage(null);
		}
	};

	const validateRequiredFields = () => {
		if (props.data !== undefined && fields && fields.page_fields) {
			if (props.data.length > 0) {
				let isValid = true;
				for (let fieldIndex = 0; fieldIndex < fields.page_fields.length; fieldIndex++) {
					let field = fields.page_fields[fieldIndex];
					if (
						field.required &&
						props.data &&
						props.data[fieldIndex] &&
						props.data[fieldIndex].value !== undefined
					) {
						switch (field.dataType) {
							case 'SingleSelectBox':
							case 'SingleSelect':
							case 'Email':
							case 'TextField':
							case 'Number':
								if (props.data[fieldIndex].value === '') isValid = false;
								break;
							case 'MultiSelectBox':
							case 'MultiSelect':
								if (props.data[fieldIndex].value === '') isValid = false;
								break;
							case 'Switch':
							case 'Checkbox':
								if (
									props.data[fieldIndex].value.toString() === 'false' ||
									props.data[fieldIndex].value === ''
								)
									isValid = false;
								break;
							case 'Slider':
								if (props.data[fieldIndex].value === '') isValid = false;
								break;
							case 'Range':
								if (props.data[fieldIndex].value === '[]') isValid = false;
								break;
							default:
								isValid = true;
								break;
						}
					}
					if (!isValid) break;
				}
				return isValid;
			} else {
				return true;
			}
		} else {
			return true;
		}
	};

	const checkDependency = (field: IFields): boolean => {
		let dependentData: IDataCreate | undefined = undefined;
		let dependentField: IFields | undefined = undefined;
		let renderit: boolean = false;
		if (props.data && fields && fields.page_fields) {
			dependentData = props.data.find(
				(el) => field.dep_id !== null && el.field_id === field.dep_id,
			);
			dependentField = fields.page_fields.find(
				(el) => field.dep_id !== null && el.id === field.dep_id,
			);
		}
		if (dependentData === undefined || dependentField === undefined) {
			return true;
		} else {
			renderit = !!(
				props.isCreator ||
				field.dep_id === null ||
				(field.dep_info && typeof field.dep_info === 'object')
			);
			if (field.dep_info !== null && typeof field.dep_info !== 'string') {
				if (dependentField.dataType === 'Number' || dependentField.dataType === 'Slider') {
					return renderit && renderByDependencyOperator(field, dependentData);
				} else if (dependentField.dataType === 'Range') {
					if (dependentData.value !== undefined && dependentData.value !== '') {
						let values = JSON.parse(dependentData.value);
						return (
							renderit &&
							Number(field.dep_info.min) <= values[0] &&
							Number(field.dep_info.max) >= values[1]
						);
					} else {
						return false;
					}
				} else if (dependentField.dataType === 'MultiSelect' || dependentField.dataType === 'MultiSelectBox') {
					let ar: string[] = JSON.parse(dependentData.value);

					if (field.dep_info !== null && field.dep_info.expected !== '') {
						return (
							renderit &&
							JSON.stringify(JSON.parse(field.dep_info.expected).sort()) ===
								JSON.stringify(ar.sort())
						);
					} else {
						return true;
					}
				} else {
					return renderit && field.dep_info.expected === dependentData.value;
				}
			} else {
				return true;
			}
		}
	};

	const RenderField = (field: IFields, fieldIndex: number): ReactElement => {
		let renderit: boolean = false;
		if (typeof field.dep_info === 'string') field.dep_info = JSON.parse(field.dep_info);
		if (fields) {
			renderit = checkDependency(field);
			if (renderit) {
				const formFieldProps: IFormFieldFormProps = {
					field: field,
					fieldIndex: fieldIndex,
					data: props.data,
					setData: props.setData,
					pageNumber: props.pageNumber,
				};

				switch (field.dataType) {
					case 'TextField':
						return <ETextFieldForm {...formFieldProps} />;
					case 'Number':
						return <ENumberFieldForm {...formFieldProps} />;
					case 'Email':
						return<EEmailFormField {...formFieldProps} validateEmail={validateEmail} />;
					case 'Checkbox':
					case 'Switch':
						return <ESwitchFormField {...formFieldProps} />;
					case 'MultiSelect':
						return <EMultiselectForm {...formFieldProps} />;
					case 'MultiSelectBox':
						return <EMultiSelectBoxFormField {...formFieldProps} />;
					case 'SingleSelect':
						return <ESingleselectForm {...formFieldProps} />;
					case 'SingleSelectBox':
						return <ESingleSelectBoxFormField {...formFieldProps} />;
					case "Seperator":
						return <ESeperator {...formFieldProps} />
					case 'Slider':
						return <ESliderForm {...formFieldProps} />;
					case 'Range':
						return <ERangeFormField {...formFieldProps} />;
					case 'File':
						return (
							<CustomFileField
								field={field}
								className='MPage__form__fileupload-button'
								label={t('upload')}
								handleFileChange={(e) => {
									if (!props.isCreator) {
										handleFileUpload(e, fieldIndex);

									}
								}}
								accept={JSON.parse(field.valuesToBeSelected).fieldType}
								key={fieldIndex}
							/>
						);
					case 'Appointment':
						return <AppointmentPicker {...formFieldProps}/>;
					default:
						return <></>;
				}
			} else return <></>;
		} else return <></>;
	};

	const handleFileUpload = (e: any, fieldIndex: number) => {
		if (props.data) {
			let formData = new FormData();
			formData.append('file', e.target.files[0]);
			formData.append('field_id', props.data[fieldIndex].field_id.toString());
			formData.append('field_type', props.data[fieldIndex].field_type);
			formData.append('value', '');

			toast.promise(
				uploadFormDataFile(props.form_uuid, formData).then((res: string) => {
					if (props.uploadedFilePaths && props.setUploadedFilePaths) {
						let uploadedFPs = [...props.uploadedFilePaths];
						let fpObj = { ...uploadedFPs[props.currentPage] };
						fpObj[fieldIndex.toString()] = res;
						uploadedFPs[props.currentPage] = fpObj;
						props.setUploadedFilePaths(uploadedFPs);
						if(props.data && props.setData) {
							let changedValues = [...props.data]
							changedValues[fieldIndex].value = "uploaded"
							props.setData((prevState: any) => {
								let newState = [...prevState];
								newState[props.pageNumber] = changedValues;
								return newState;
							});
						}
					}
				}),
				{
					loading: 'Uploading File',
					success: 'Successfully Uploaded File - ' + e.target.files[0].name,
					error: 'Error something went wrong uploading your File!',
				},
				{
					duration: 3000,
				},
			);
		}
	};

	return (
		<div
			className={
				'MPage' +
				(props.currentPage === props.pageNumber ? ' visible' : '') +
				((props.currentPage === 0 || props.currentPage === props.totalPages - 1) &&
				props.pageInfo.page_fields?.length === 0
					? ' align-items-center'
					: '')
			}>
			<div className='MPage__details'>
				{props.pageInfo && (
					<div className='MPage__pages'>
						{ // don't show page infos if page has no field and page is not last page
							!(props.pageInfo.page_fields?.length === 0 && props.pageNumber === 0) &&
							props.currentPage < props.totalPages - 1 && (
								<>{t('page')} {props.history.length}</>
							)
						}
					</div>
				)}
				<div
					className={
						'MPage__title' +
						(props.pageInfo && props.pageInfo.page_fields?.length === 0
							? ' centered'
							: '')
					}>
					{props.pageInfo.title}
				</div>
				<div
					className={
						'MPage__description' +
						(props.pageInfo && props.pageInfo.page_fields?.length === 0
							? ' centered'
							: '')
					}
					dangerouslySetInnerHTML={{ __html: props.pageInfo.description }}></div>
			</div>
			<div
				ref={scrollableRef}
				className={
					'MPage__form' +
					((props.currentPage === 0 || props.currentPage === props.totalPages - 1) &&
					props.pageInfo.page_fields?.length === 0
						? ' h-0'
						: '')
				}>
				{
					(props.allData && (props.pageNumber ===  props.totalPages - 2)) && (
						<div className="MPage__form__answer-overview">
							<h3 className="MPage__form__answer-overview__headline">{t('yourAnswers')}</h3>
							{
								props.allData.map((data, dataIndex)=> {
									if(props.history.includes(dataIndex)) {
										return data.map((dataObj, index)=>{

											if (dataObj.field_name === 'Seperator') {
												return (<></>)
											}

											let fieldValue : any = dataObj.value

											try {
												fieldValue = JSON.parse(fieldValue)
												if(fieldValue instanceof Array) {
													if(fieldValue.length > 1 ) {
														fieldValue = fieldValue.toString()
													} else if (fieldValue.length === 1) {
														fieldValue = fieldValue[0]
													} else {
														fieldValue = ""
													}
												} else fieldValue = dataObj.field_type === 'Appointment' ? moment(dataObj.value).format("DD.MM.YYYY HH:mm") : dataObj.value
											} catch (e) {
												fieldValue = dataObj.field_type === 'Appointment' ? moment(dataObj.value).format("DD.MM.YYYY HH:mm") : dataObj.value
											}
											return (
												<div key={index} className="MPage__form__answer-overview__answer">
													{fields && fields.page_fields && (
														<>
															<div>{dataObj.field_name !== undefined ? dataObj.field_name : ""}</div><div>{fieldValue}</div>
														</>
													)}
												</div>
											)
										})
									} else {
										return <></>
									}
								})
							}
						</div>
					)
				}
				{fields?.page_fields?.map((field, fieldIndex) => {
					return (
						<DragDropWrapper
							className={
								'MPage__drop-target' +
								(props.isCreator ? ' isCreator' : '') +
								(field.order === hoveredDropTarget ? ' isOver' : '')
							}
							key={field.id}
							item={field}
							moveItem={moveField}
							pageNumber={props.pageNumber}
							fields={fields}
							type={[props.accepts, 'field']}
							hoveredDropTarget={hoveredDropTarget}
							onDragOver={() => handleHover(field.order)}
							onDragLeave={handleDragEnd}
							onDrop={props.onDrop}
							isCreator={props.isCreator}>
							<form onSubmit={(e: any) => {}} >
								{props.isCreator && field.id !== undefined && (
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
										}}>
										<Button
											variant='outlined'
											onClick={() => {
												if (props.setNewField && props.setFieldFormOpen) {
													props.setNewField(field);
													props.setFieldFormOpen(true);
												}
											}}>
											<EditRounded sx={{ fontSize: '15px', ml: 0.3 }} /> {t('edit')}
										</Button>
										<Button
											variant='outlined'
											onClick={() => {
												if (props.removeItem) {
													props.removeItem(fieldIndex, props.pageNumber);
												}
											}}>
											<DeleteIcon sx={{ fontSize: '15px', mr: 0.3 }} /> {t('delete')}
										</Button>
									</Box>
								)}
								<EErrorBoundary msg={"Something went wrong showing this field..."}>
										{RenderField(field, fieldIndex)}
								</EErrorBoundary>
							</form>
						</DragDropWrapper>
					);
				})}
				{props.isCreator &&
					props.addNewItem &&
					props.pageInfo &&
					props.pageInfo.uuid &&
					props.pageInfo.page_fields !== undefined &&
					props.currentPage !== props.totalPages - 1 &&
					props.currentPage !== props.totalPages - 2 && (
						<div className='mt-2 d-flex justify-content-center'>
							<EButton
								theme={'primary'}
								onClick={() => {
									if (props.addNewItem) {
										props.addNewItem(emptyField, props.pageNumber);
									}
								}}>
								{t('newField')}
							</EButton>
						</div>
					)}
				{props.message && (
					<EMessage message={props.message.message} type={props.message.type} />
				)}
			</div>
		</div>
	);
};

export default MPage;
