import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import React, {SetStateAction, useEffect, useRef, useState, useTransition} from "react";
import { IPages } from "../frontend/src/Interfaces/IPages";
import Input from "./bootstrap/forms/Input";
import { initialFields } from "../frontend/src/Utils/objects";
import { DraggableItem } from "./DraggableItem";
import Button from "./bootstrap/Button";
import { ArrowBackIos, ArrowForwardIos, Delete, Save } from "@mui/icons-material";
import StyleForm from "./StyleForm";
import { Page } from "../models/pages/pages.module";
import { updatePage } from "../models/pages/pages.repository";
import { Form } from "../models/forms/forms.module";
import ImageForm from "./ImageForm";
import Label from "./bootstrap/forms/Label";
import { Box } from "@mui/material";
import toast from "react-hot-toast";
import ReactQuill from "react-quill";
import {useTranslation} from "react-i18next";

interface IPageFormProps {
	pageForm: IPages;
	currentForm: Form;
	setPageForm: React.Dispatch<SetStateAction<IPages | null>>;
	removeLastPage: () => void;
	removeLastItem: () => void;
	formEditMode: '' | 'image' | 'style' | 'page-field' | 'page-style' | 'page-image' | 'page' | 'template';
	setFormEditMode: React.Dispatch<
		SetStateAction<'' | 'image' | 'style' | 'page-field' | 'page-style' | 'page-image' | 'page' | 'template'>
	>;
	setCurrentForm: React.Dispatch<SetStateAction<Form | null>>;
	savePage: () => void;
	deletePage: () => void;
	getAllForms: () => void;
	currentPage: number;
}

export const PageForm: (props: IPageFormProps) => ReactJSXElement = (props: IPageFormProps) => {

	const { t } = useTranslation(['translation', 'menu']);

	const [formState, setFormState] = useState<IPages | null>(null);
	const quillRef = useRef<ReactQuill>(null);
	const [isPending, startTransition] = useTransition();

	const modules = {
		toolbar: [
			[{ header: [1, 2, 3, false] }],
			['bold', 'italic', 'underline', 'strike', 'blockquote'],
			[{ list: 'ordered' }, { list: 'bullet' }],
			[{ align: [] }],
			[{ color: [] }, { background: [] }],
			['uploadImage'],
			['clean'],
		]
	};

	useEffect(() => {
		if(props.pageForm) {
			setFormState(props.pageForm)
		}
	}, [])

	const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if(formState) {
			let newPage : IPages = {...formState, title: e.target.value}
			let newForm : Form = {...props.currentForm}
			newForm.pages![props.currentPage] = newPage;
			setFormState(newPage);
			props.setPageForm(newPage);
			props.setCurrentForm(newForm);
		}
	};

	const handleDescriptionChange = (content: string) => {
		if(formState) {
			let newPage : IPages = {...formState, description: content }
			let newForm : Form = {...props.currentForm}
			newForm.pages![props.currentPage] = newPage;
			setFormState(newPage);
			props.setPageForm(newPage);
			props.setCurrentForm(newForm);
		}
	};

	const handlePageFormUpdate = (id?: string, body?: Partial<Page>) => {
		if (id && body) {
			updatePage(id, body)
				.then((res) => {
					if (res) {
						props.setCurrentForm(res);
					}
					props.getAllForms();
				})
				.catch((error) => console.log(error));
		}
	};

	const saveFormStyle = (styleObj: { [key: string]: string }) => {

		if (formState && formState.uuid) {
			let styleArr = Object.keys(styleObj).map(styleAttribute => {
				return {
					name: styleAttribute,
					value: styleObj[styleAttribute]
				}
			})
			toast.promise(
				updatePage(formState.uuid, { page_styles: styleArr })
					.then((res) => {
						if (res) {
							props.setCurrentForm(res);
						}
						props.getAllForms();
					}), {
				loading: 'Loading',
				success: 'Successfully saved form',
				error: 'Error something went wrong!'
			});
		}
	}

	return (
		<>
			{(props.formEditMode === '' || props.formEditMode === 'page') && (formState) && (
				<>
					<Button
						color={'dark'}
						onClick={() => {
							if (props.pageForm?.uuid === undefined) {
								props.removeLastPage();
							}
							props.setPageForm(null);
							props.setFormEditMode('page')
						}}>
						<ArrowBackIos />
					</Button>
					<div>
						<Label>{t('pagetitle')}</Label>
						<Input
							placeholder={t('pagetitle')}
							onChange={handleTitleChange}
							value={formState?.title || ''}
						/>
					</div>
					<div>
						<Label>{t('pagedescription')}</Label>
						<ReactQuill
							id='body'
							ref={quillRef}
							theme='snow'
							modules={modules}
							value={formState.description || ''}
							onChange={(content) => handleDescriptionChange(content)}
						/>
					</div>
					{
						formState && (formState.uuid !== undefined) && (
							<>
								<Button
									color='dark'
									onClick={() => {
										props.setFormEditMode('page-field');
									}}>
									{t('editFields')}<ArrowForwardIos />
								</Button>
								<Button
									color='dark'
									onClick={() => {
										props.setFormEditMode('page-style');
									}}>
									{t('editPageStyles')}<ArrowForwardIos />
								</Button>
								<Button
									color='dark'
									onClick={() => {
										props.setFormEditMode('page-image');
									}}>
									{t('uploadBackground')} <ArrowForwardIos />
								</Button>
							</>
						)
					}
					<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 15 }}>
						{
							(props.pageForm.uuid && props.pageForm.uuid !== '') &&
							<Button
								style={{ flex: 1 }}
								color='warning'
								isDisable={props.pageForm.title === '' || props.pageForm.description === '' || props.pageForm.isLastPage}
								onClick={() => {
									props.deletePage();
								}}>
								<Delete />
							</Button>
						}
						<Button
							style={{ flex: 1 }}
							color='success'
							isDisable={props.pageForm.title === '' || props.pageForm.description === ''}
							onClick={() => {
								props.savePage();
							}}>
							<Save />
						</Button>
					</Box>
				</>
			)}

			{props.formEditMode === 'page-style' && (
				<StyleForm
					currentForm={props.pageForm}
					setFormEditMode={props.setFormEditMode}
					formEditMode={props.formEditMode}
					handleFormUpdate={saveFormStyle}
					formStyles={props.currentForm.styles}
				/>
			)}

			{props.formEditMode === 'page-field' && (
				<>
					<Button
						color='dark'
						onClick={() => {
							props.setFormEditMode('page');
						}}>
						<ArrowBackIos />
					</Button>
					{initialFields.map((field, fieldIndex) => {
						return <DraggableItem key={fieldIndex} {...field} />;
					})}
				</>
			)}

			{props.formEditMode === 'page-image' && formState &&(
				<ImageForm
					setFormEditMode={props.setFormEditMode}
					formEditMode={props.formEditMode}
					currentForm={formState}
					formObject='Page'
					companyId={props.currentForm.company_id}
					getAllForms={props.getAllForms}
					setCurrentForm={props.setCurrentForm}
				/>
			)}
		</>
	);
};
